<script>
import useVuelidate from '@vuelidate/core'
import {maxLength, required, requiredIf, requiredUnless} from '@vuelidate/validators'

/* COMPOSANTS */
import AkFormView from "@components/layout/AkFormView";
import AkFormSubmitted from "@components/general/AkFormSubmitted";
import AkDropdown from "@components/input/AkDropdown";
import AkInputText from "@components/input/AkInputText";
import AkInputTextArea from "@components/input/AkInputTextArea";
import AkInputNumber from "@components/input/AkInputNumber";
import AkCheckbox from "@components/input/AkCheckbox";

/* MIXINS */
import randomRef from "@mixins/randomRef";
import roleMixin from "@mixins/roleMixin";

/* SERVICES */
import agerService from "@services/agerService";
import uerService from "@services/uerService";
import ceiService from "@services/ceiService";
import bilanMaterielService from "@services/bilanMaterielService";
import circuitService from "@services/circuitService";
import rolePermissionService from "@services/rolePermissionService";

export default {
  components: {AkInputText, AkCheckbox, AkFormView, AkFormSubmitted, AkDropdown, AkInputTextArea, AkInputNumber},
  mixins: [randomRef, roleMixin],
  setup() {
    return {v$: useVuelidate()}
  },
  metaInfo() {
    return {
      title: "bilan.create"
    }
  },
  data() {
    return {
      submitted: false,
      alreadyExists: false,
      agerList: [],
      uerList: [],
      ceiList: [],
      circuitList: [],
      disableAger: null,
      disableUer: null,
      disableCei: null,
      current: {
        ceiId: null,
        uerId: null,
        agerId: null,
      },
      truckTypes: [
        {label: "16 T", value: "16 T"},
        {label: "19 T", value: "19 T"},
        {label: "26 T", value: "26 T"}
      ],
      volumeUnits: [
        {label: this.$t('bilan_materiel.v3m3'), value: "v3m3"},
        {label: this.$t('bilan_materiel.v4a45m3'), value: "v4a45m3"},
        {label: this.$t('bilan_materiel.v5m3'), value: "v5m3"},
        {label: this.$t('bilan_materiel.v6m3'), value: "v6m3"},
        {label: this.$t('bilan_materiel.v7a8m3'), value: "v7a8m3"}
      ]
    }
  },
  validations() {
    return {
      current: {
        agerId: {required},
        uerId: {required: requiredUnless(this.current.isMule)},
        ceiId: {required: requiredUnless(this.current.isMule)},
        circuitId: {required: requiredUnless(this.current.isMule)},
        mule: {required: requiredIf(this.current.isMule)},
        brand: {maxLength: maxLength(100)},
        problemComment: {maxLength: maxLength(500)},
      }
    }
  },
  mounted() {
    this.getRef().showTotalLoader();
    
    let p1 = agerService.findAllCurrent();
    p1.then(data => this.agerList = data);
    
    let p2 = uerService.findAllCurrent();
    p2.then(data => this.uerList = data);
    
    let p3 = ceiService.findAllCurrent();
    p3.then(data => this.ceiList = data);
  
    let p4 = circuitService.findAllCurrent();
    p4.then(data => this.circuitList = data);
  
    let p5 = rolePermissionService.authorizeAccessByPermission(this.permissionConst.equipmentReview.edit);
  
    Promise.all([p1, p2, p3, p4, p5]).then(()=>{
      this.initData();
      this.getRef().hideLoader();
    });
  },
  methods: {
    initData() {
      if (this.currentUser.agerId) {
        this.disableAger = true;
        this.current.agerId = this.currentUser.agerId;
      }
      if (this.currentUser.uerId) {
        this.disableUer = true;
        this.current.uerId = this.currentUser.uerId;
      }
      if (this.currentUser.ceiId) {
        this.disableCei = true;
        this.current.ceiId = this.currentUser.ceiId;
      }
    },
    create() {
      if (!this.checkForm()) {
        this.getRef().error(this.$t("bilan.error.create"));
        return;
      }
      
      this.getRef().showLoader(this.$refs.form)
      bilanMaterielService.create(this.current).then(data => {
        let msg = this.$t("bilan.added");
        this.$router.replace({ path: `/bilan/materiel/${data.id}/details`, query: { msg: msg } });
      }).catch(e=>{
          this.getRef().hideLoader();
          this.getRef().error(this.$t("error."+e.response.data.error));
          this.submitted = false;
        }
      );
    },
    checkForm() {
      this.getRef().resetMessages();
      this.submitted = true;
      this.v$.$touch();
      return !this.v$.$error;
    },
    testExistenceCircuit(circuitId) {
      bilanMaterielService.findByCircuitId(circuitId).then(data => this.testExistence(data));
    },
    testExistenceMule(mule) {
      bilanMaterielService.findByMule(mule).then(data => this.testExistence(data));
    },
    testExistence(data) {
      if (!data) this.alreadyExists = false;
      else {
        this.alreadyExists = true;
        this.current.truckType = data.truckType;
        this.current.brand = data.brand;
        this.current.location = data.location;
      }
    }
  },
  computed: {
    uerListForCurrent() {
      return this.uerList.filter(uer => uer.agerId === this.current.agerId);
    },
    ceiListForCurrent() {
      return this.ceiList.filter(cei => cei.uerId === this.current.uerId);
    },
    circuitListForCurrent() {
      return this.circuitList.filter(circuit => circuit.ceiId === this.current.ceiId);
    }
  },
  watch: {
    uerListForCurrent() {
      if(!this.uerListForCurrent.some(uer => uer.id===this.current.uerId))
        this.current.uerId = undefined;
    },
    ceiListForCurrent() {
      if(!this.ceiListForCurrent.some(cei => cei.id===this.current.ceiId))
        this.current.ceiId = undefined;
    },
    'current.circuitId'(newValue) {
      this.testExistenceCircuit(newValue);
    },
    'current.mule'(newValue) {
      this.testExistenceMule(newValue);
    },
    'current.isMule'() {
      if (this.current.circuitId) {
        this.current.circuitId = null;
        this.current.ceiId = null;
        this.current.uerId = null;
      }
      if (this.current.mule) this.current.mule = null;
      this.alreadyExists = false;
    }
  }
}
</script>

<template v-if=!roleLoading>
  <AkFormView :ref="ref" :title="$t('bilan.create')">
    <template v-slot:form>
      <AkFormSubmitted :submitted=submitted reference="form">
        <div class="form-row">
          <AkCheckbox v-model="current.isMule"
                      :label="$t('bilan_materiel.new_mule')"
                      class-name="col-md-3"/>
        </div>
        <div class="form-row">
          <AkDropdown :label="$t('ager_label')"
                      v-model="current.agerId"
                      :validator="v$.current.agerId"
                      :submitted=this.submitted
                      :options=this.agerList
                      :disabled=disableAger
                      option-value="id"
                      option-label="label"
                      class-name="col-md-3"/>
          <AkDropdown :label="$t('uer_label')"
                      v-if="current.agerId && !current.isMule"
                      v-model="current.uerId"
                      :validator="v$.current.uerId"
                      :submitted=this.submitted
                      :options=this.uerListForCurrent
                      :disabled=disableUer
                      option-value="id"
                      option-label="label"
                      class-name="col-md-3"/>
          <AkDropdown :label="$t('cei_label')"
                      v-if="current.uerId && !current.isMule"
                      v-model="current.ceiId"
                      :validator="v$.current.ceiId"
                      :submitted=this.submitted
                      :options=this.ceiListForCurrent
                      :disabled=disableCei
                      option-value="id"
                      option-label="label"
                      class-name="col-md-3"/>
          <AkDropdown :label="$t('circuit_label')"
                      v-if="current.ceiId && !current.isMule"
                      v-model="current.circuitId"
                      :validator="v$.current.circuitId"
                      :submitted=this.submitted
                      :options=this.circuitListForCurrent
                      option-value="id"
                      option-label="axis"
                      class-name="col-md-3"/>
          <AkInputNumber v-if=current.isMule
                         :label="$t('bilan_materiel.mule_number')"
                         v-model="current.mule"
                         :min=1
                         class-name="col-md-3"/>
        </div>
        <h5>{{ $t('bilan_materiel.truck_info') }}</h5>
        <div class="form-row">
          <AkDropdown :label="$t('bilan_materiel.truck_type')"
                      :disabled=alreadyExists
                      v-model="current.truckType"
                      :submitted=this.submitted
                      :options=this.truckTypes
                      option-value="value"
                      option-label="label"
                      class-name="col-md-4"/>
          <AkInputText :label="$t('bilan_materiel.brand')"
                       :disabled=alreadyExists
                       v-model="current.brand"
                       :validator="v$.current.brand"
                       :submitted=this.submitted
                       class-name="col-md-4"/>
        </div>
        <div class="form-row">
          <AkCheckbox v-model="current.location"
                      :disabled=alreadyExists
                      :label="$t('bilan_materiel.rent')"
                      class-name="col-md-3"/>
        </div>
        <h5>{{ $t('bilan_materiel.salting') }}</h5>
        <div class="form-row">
          <AkDropdown :label="$t('bilan_materiel.salt_unit')"
                      v-model="current.volumeUnit"
                      :submitted=this.submitted
                      :options=this.volumeUnits
                      option-value="value"
                      option-label="label"
                      class-name="col-md-4"/>
        </div>
        <div class="form-row">
          <AkCheckbox v-model="current.brine"
                      :label="$t('bilan_materiel.brine')"
                      class-name="col-md-4"/>
        </div>
        <h5>{{ $t('bilan_materiel.repairs_and_problems') }}</h5>
        <div class="form-row">
          <AkInputNumber :label="$t('bilan_materiel.nb_repair_materiel_vh')"
                         v-model="current.repairNumber"
                         class-name="col-md-4"/>
                         
          <AkInputTextArea :label="$t('bilan_materiel.problem_met_and_suggestions')"
                           v-model="current.problemComment"
                           :validator="v$.current.problemComment"
                           :submitted=this.submitted
                           class-name="col-md-8"/>
        </div>
        <div class="float-right">
          <button v-if=this.canCreateEquipment() @click=create() class="btn btn-primary" style="margin-left: 10px">{{ $t('save') }}</button>
        </div>
      </AkFormSubmitted>
    </template>
  </AkFormView>
</template>